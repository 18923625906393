import request from '@/utils/request'

//获取商户合作数据
export function getData(params) {
  return request({ url: '/web/mch/official/coop', method: 'get', params})
}
//添加合作意向
export function addIntention(data) {
  return request({ url: '/web/mch/official/coop/intention', method: 'post', data})
}
